<template>
  <div id="customer-orders">
    <Navigation />
    <v-main class="content mb-9">
      <v-container fluid>
        <v-card style="border-radius: 2px" color="#383E45">
          <v-container fluid class="py-5 px-6">
            <HeaderPage title="ลูกค้าสัมพันธ์" />

            <v-col cols="12" class="px-0">
              <v-row>
                <v-col cols="12" md="8" lg="5">
                  <label>ฟิลเตอร์ลูกค้า</label>
                  <v-select
                    v-model="selectedGradeCustomer"
                    :items="gradeCustomerItems"
                    solo
                    dense
                    class="mt-2"
                    hide-details
                    item-value="value"
                    item-text="text"
                    @change="filterAndSortCustomerOrder('filter')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="d-flex align-end">
                  <v-btn
                    color="primary"
                    @click.prevent="filterAndSortCustomerOrder('sort')"
                    ><v-icon left>mdi-sort</v-icon>
                    {{ sort === 2 ? 'มากไปน้อย' : 'น้อยไปมาก' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <div class="wrapper-table mb-1 mt-3">
              <table class="table-table">
                <tr class="table-head">
                  <th>#</th>
                  <th>ชื่อ-นามสกุล</th>
                  <th>ชื่อ Facebook</th>
                  <th>เบอรโทรศัพท์</th>
                  <th>จำนวนสินค้า</th>
                  <th>มูลค่าออเดอร์ทั้งหมด</th>
                  <th>จัดการ</th>
                </tr>
                <tr v-if="customerOrders.length === 0">
                  <td colspan="10">
                    <template>
                      <div
                        style="font-size: 20px"
                        class="d-flex flex-column align-center red--text my-4"
                      >
                        <v-img
                          class="mt-2 mb-4"
                          width="200"
                          src="@/assets/no-data.svg"
                          alt=""
                        >
                        </v-img>
                        ไม่พบข้อมูลรายการลูกค้าสัมพันธ์
                      </div>
                    </template>
                  </td>
                </tr>
                <tr
                  class="table-body"
                  v-for="(data, index) in customerOrders"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ data.first_name ? data.first_name : '-' }}
                    {{ data.last_name }}
                  </td>
                  <td>{{ data.facebook_name }}</td>
                  <td>{{ data.phone }}</td>
                  <td>{{ data.total_quantity | currency2 }}</td>
                  <td>{{ data.total | currency2 }}</td>
                  <td>
                    <v-btn
                      class="white--text"
                      small
                      color="primary"
                      @click.prevent="
                        $router.push(
                          `/management/customer-order/${data.customer_id}`
                        )
                      "
                      >ดูสินค้าทั้งหมด</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
            <!-- <div class="text-center mt-2">
              <v-pagination
                v-model="params.page"
                :length="totalPages"
                :total-visible="7"
                @input="getTrackingOrders"
              ></v-pagination>
            </div> -->
          </v-container>
        </v-card>
      </v-container>
    </v-main>
    <FooterAdmin />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import FooterAdmin from '@/components/FooterAdmin';
import HeaderPage from '@/components/HeaderPage';

import CustomerService from '@/services/Customer.service';
import Swal from 'sweetalert2';
export default {
  name: 'CustomerOrders',
  components: {
    Navigation,
    HeaderPage,
    FooterAdmin,
  },
  data() {
    return {
      params: {
        sort: 2,
        page: 1,
        limit: 99999999999999,
      },
      sort: 2,
      totalPages: 1,
      totalElement: 0,
      customerOrders: [],
      customerOrdersTemp: [],
      gradeCustomerItems: [
        { value: 'all', text: 'ทั้งหมด' },
        { value: 'vip', text: 'GRADE VIP' },
        { value: 'a', text: 'GRADE A' },
        { value: 'b', text: 'GRADE B' },
        { value: 'c', text: 'GRADE C' },
        { value: 'd', text: 'GRADE D' },
        { value: 'e', text: 'GRADE E' },
      ],
      selectedGradeCustomer: 'all',
    };
  },
  created() {
    this.getCustomerOrders();
  },
  methods: {
    filterAndSortCustomerOrder(aciotn) {
      this.customerOrders = this.customerOrdersTemp;
      let filtered = [];
      if (this.selectedGradeCustomer === 'all') {
        filtered = this.customerOrders;
      } else if (this.selectedGradeCustomer === 'vip') {
        filtered = this.customerOrders.filter((item) => {
          return Number(item.total_quantity) >= 16;
        });
      } else if (this.selectedGradeCustomer === 'a') {
        filtered = this.customerOrders.filter((item) => {
          return (
            Number(item.total_quantity) >= 12 &&
            Number(item.total_quantity) < 16
          );
        });
      } else if (this.selectedGradeCustomer === 'b') {
        filtered = this.customerOrders.filter((item) => {
          return (
            Number(item.total_quantity) >= 8 && Number(item.total_quantity) < 12
          );
        });
      } else if (this.selectedGradeCustomer === 'c') {
        filtered = this.customerOrders.filter((item) => {
          return (
            Number(item.total_quantity) >= 4 && Number(item.total_quantity) < 8
          );
        });
      } else if (this.selectedGradeCustomer === 'd') {
        filtered = this.customerOrders.filter((item) => {
          return (
            Number(item.total_quantity) >= 2 && Number(item.total_quantity) < 4
          );
        });
      } else if (this.selectedGradeCustomer === 'e') {
        filtered = this.customerOrders.filter((item) => {
          return (
            Number(item.total_quantity) >= 0 && Number(item.total_quantity) < 2
          );
        });
      }

      if (this.sort === 1) {
        this.customerOrders = filtered.sort((a, b) => {
          return aciotn === 'sort' ? b.total - a.total : a.total - b.total;
        });
        if (aciotn === 'sort') this.sort = 2;
        return;
      }
      if (this.sort === 2) {
        this.customerOrders = filtered.sort((a, b) => {
          return aciotn === 'sort' ? a.total - b.total : b.total - a.total;
        });
        if (aciotn === 'sort') this.sort = 1;
        return;
      }
    },

    async getCustomerOrders() {
      try {
        const response = await CustomerService.customerOrders(this.params);
        this.customerOrders = response.customerOrders.sort((a, b) => {
          return b.total - a.total;
        });
        this.customerOrdersTemp = response.customerOrders.sort((a, b) => {
          return b.total - a.total;
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: error.msg,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    // sortCustomer() {
    //   if (this.params.sort == 2) {
    //     this.params.sort = 1;
    //     this.getCustomerOrders();
    //   } else {
    //     this.params.sort = 2;
    //     this.getCustomerOrders();
    //   }
    // },
  },
};
</script>

<style></style>
